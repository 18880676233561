<template>
  <CSimpleGrid :columns="3" mt="10px" :spacing="5">
    <CBox v-for="(item, index) in currentWebCVList" :key="index" style="position: relative;">
      <CImage :src="require(`@/assets/web-cv/web-cv-${item.slug}.png`)" objectFit="cover" objectPosition="top" h="250px" w="100%" boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)" borderRadius="5px"  />
      <CBox v-if="item.is_coming_soon" h="250px" w="100%" bg="comingSoon" transform="translateY(-100%)" pos="absolute" align="center" borderRadius="5px">
        <CText d="table-cell" verticalAlign="middle" color="white" w="100%" h="inherit" fontWeight="bold">Coming Soon</CText>
      </CBox>
      <CBox v-if="currentUser.web_cv_design_converted == item.slug" h="250px" w="100%" transform="translateY(-100%)" pos="absolute" align="center" borderWidth="5px" borderColor="activeMenu" borderRadius="5px">
        <!-- <CText d="table-cell" verticalAlign="middle" color="white" w="100%" h="inherit" fontWeight="bold">Dipilih</CText> -->
        <CIcon d="table-cell" h="inherit" color="comingSoon" name="check-circle" w="60px" verticalAlign="middle" />
      </CBox>
      <!-- <CPseudoBox v-if="!item.is_coming_soon && currentUser.web_cv_design_converted !== item.slug" h="250px" w="100%" transform="translateY(-100%)" pos="absolute" align="center" :_hover="{ cursor: 'pointer', borderWidth: '5px', borderColor:'activeMenu', borderRadius:'5px' }">
        <CIcon d="table-cell" h="inherit" color="comingSoon" name="check-circle" w="60px" verticalAlign="middle" />
      </CPseudoBox> -->
      <CPseudoBox v-if="!item.is_coming_soon && currentUser.web_cv_design_converted !== item.slug" h="250px" w="100%" transform="translateY(-100%)" pos="absolute" align="center" cursor="pointer" borderWidth="5px" borderColor="activeMenu" borderRadius="5px" opacity="0" :_hover="{ opacity: '100' }" @click.native="chooseDesign(item.slug)"></CPseudoBox>
      <CBox align="center" mx="auto" my="10px">
        <CText fontWeight="bold" align="center" mb="10px" color="fontMain">{{ item.name }}</CText>
        <!-- <CBox v-if="!item.is_coming_soon">
          <ButtonSecondary2 v-if="currentUser.web_cv_design_converted == item.slug" label="Dipilih" />
          <ButtonBlue v-else label="Pilih" @click.native="chooseDesign(item.slug)" :disabled="disabledButton" />
        </CBox> -->
      </CBox>
    </CBox>
  </CSimpleGrid>
</template>

<script>
// import ButtonBlue from "@/components/button/ButtonBlue.vue"
// import ButtonSecondary2 from "@/components/button/ButtonSecondary.vue"

export default {
  props: {
    currentUser: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: "instant"
    },
    disabledButton: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    // ButtonBlue,
    // ButtonSecondary2
  },
  data() {
    return {
      slug: "",
      // dataDisabledButton: this.disabledButton,
    }
  },
  computed: {
    currentWebCVList() {
      const list =  this.$store.getters.currentWebCVList;
      const listUsed = [];
      for (const d of list) {
        if (this.currentUser.is_mentor && d.is_mentor) {
          listUsed.push(d);
        }
        if (!this.currentUser.is_mentor && !d.is_mentor) {
          listUsed.push(d);
        }
      }
      return listUsed;
    }
  },
  methods: {
    chooseDesign(slug) {
      // if (this.type == "instant") {
        this.$emit('chooseDesign', slug);
      // } else {
      //   this.slug = slug;
      // }
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
