import { render, staticRenderFns } from "./CompChooseWebCV.vue?vue&type=template&id=0616ab4c&scoped=true&"
import script from "./CompChooseWebCV.vue?vue&type=script&lang=js&"
export * from "./CompChooseWebCV.vue?vue&type=script&lang=js&"
import style0 from "./CompChooseWebCV.vue?vue&type=style&index=0&id=0616ab4c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0616ab4c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CIcon: require('@chakra-ui/vue').CIcon, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CSimpleGrid: require('@chakra-ui/vue').CSimpleGrid})
